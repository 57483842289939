<template>
  <div id="video-widget">
    <div class="video-player" v-if="selectedVideo.length > 0">
      <i
        class="box-icon bx bx-x video-player__close"
        title="Close Preview"
        @click="closePreview"
      />
      <iframe
        class="video-player__frame"
        :src="selectedVideo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
      ></iframe>
      <!-- <hr class="video-player__bottom" /> -->
    </div>
    <div v-if="youtubeSearch.length > 0" class="youtube_search mb-3" ref="youtubeSearch" @scroll="handleScroll">
      <div
        class="youtube_search__list"
        :key="item.id"
        v-for="item in youtubeSearch"
      >
        <div
          @click="setSelectedVideo(item)"
          @mouseover="setMouseHoverIframe(item.id)"
          @mouseleave="setMouseOutIframe"
          class="youtube_search__thumbnail"
        >
          <img
            v-if="!thumbnailMouse.includes(item.id)"
            :src="item.thumbnail"
            alt=""
          />
          <iframe
            v-if="thumbnailMouse.includes(item.id)"
            class="iframe-widget"
            :src="youtubeEmbed(item.embed)"
            title="YouTube video player"
            frameborder="0"
            allow="autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            autoplay
            mute
          ></iframe>
        </div>
        <div class="youtube_search__detail">
          <div class="heading">
            <div>{{ item.title.slice(0, 20) }}...</div>
          </div>
          <div class="d-flex meta">
            <p>{{ item.uploaded_by }}</p>
            <p>{{ item.runtime }}</p>
          </div>
            <div class="noteItem-preference__btn">
              <i
                @click="addYoutubeToGraph(item)"
                title="Add to Graph"
                class="noteItem-preference__icon bx bx-cloud-snow box-icon box-icon--effect"
              ></i>

               <i
              @click="copyUrlToClipboard(item.embed)"
              title="Copy Embed URL"
              class="noteItem-preference__icon bx bx-link box-icon box-icon--effect"
            style="padding-left: 5px;"></i>
            </div>
        </div>
      </div>
    </div>
    <div v-if="youtubeSearch.length > 0" class="d-flex justify-content-center mb-3">
      <span v-if="isLoading" class="spinner-border"></span>
    </div>
    <div v-else class="px-4 py-4">
      I am ready to serve. Search something now.
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      searchWord: '',
      selectedVideo: '',
      thumbnailMouse: [],
    };
  },

  computed: {
    youtubeSearch() {
      // console.log('xvf',
      //   this.$store.getters.getYoutubeData);
      const data = this.$store.getters.getYoutubeData || [];
      return data.filter((video) => video.runtime !== 'undefined');
    },
    isGameConnected() {
      // console.log('xvf', 'is Game connected', this.$store.state.game.connected);
      return this.$store.state.game.connected;
    },
  },

  mounted() {},

  created() {
    this.$highway.singleDispatcher('youtubeSearchByKeyword');
    setTimeout(() => {
      const currentEntity = this.$store.state.current_thought?.label;
      if (currentEntity) {
        this.$store.dispatch('youtubeSearchByKeyword', currentEntity);
      }
      // console.log('xvf', );
    }, 3000);
  },

  methods: {
    setCurrentThought(word) {
      const wordObj = {
        word, label: word, type: 'string', lang: this.$store.state.locale.currentLocale,
      };
      // console.log('xvf', 'set current thought from video', wordObj);
      this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
      this.$store.dispatch('youtubeSearchByKeyword', word);
    },
    copyUrlToClipboard(url) {
      navigator.clipboard.writeText(url);
    },
    youtubeEmbed(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0&autoplay=1&mute=1&controls=0`;
        }
      }
      return '';
      // return `${embed}?autoplay=1&mute=1`;
    },
    SelectedVideoEmbed(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0&autoplay=1`;
        }
      }
      return '';
      // return `${embed}?autoplay=1&mute=1`;
    },
    async addYoutubeToGraph(item) {
      this.$store.dispatch('gmodule/addYoutubeToGraph', item);
    },

    searchYoutubeByKeyowrd() {
      this.$store.dispatch('youtubeSearchByKeyword', this.searchWord);
    },
    nextPage() {
      this.isLoading = true;
      this.$store.dispatch('youtubeSearchUsingNextPage').then(() => {
        this.isLoading = false;
      });
    },

    pageJump(code) {
      this.$store.dispatch('youtubePageJump', code);
    },
    setMouseHoverIframe(id) {
      this.thumbnailMouse = [id];
    },
    setMouseOutIframe() {
      this.thumbnailMouse = [];
    },
    setSelectedVideo(item) {
      this.selectedVideo = this.SelectedVideoEmbed(item.embed);
      if (this.isGameConnected) {
        const response = {
          data: {
            type: 'video',
            status: 200,
            video: item,
          },
        };
        window.parent.postMessage(
          JSON.stringify(response),
          process.env.VUE_APP_GAME_URI,
        );
      }
      // trigger event for watching video
      const eventPayload = {
        verb: 'WATCHED_VIDEO',
        value: {
          link: item.embed,
          title: item.title,
          transcript: item.transcript,
        },
        dataSource: 'Youtube',
      };
      this.$store.dispatch('eventToEventStore', eventPayload);
    },
    // clearSetMouse() {
    //   this.thumbnailMouse = [];
    // },
    closePreview() {
      this.selectedVideo = '';
    },
    handleScroll() {
      const container = this.$refs.youtubeSearch;
      // console.log('xvf', 'this function is working', container, this.$refs);
      if (container) {
        const scrollPosition = container.scrollTop + container.clientHeight;
        const containerHeight = container.scrollHeight;

        // If scroll position is at the bottom of the container
        if (scrollPosition >= containerHeight) {
          this.loadMoreVideos(); // Function to load more videos when scrolled to the end
        }
      }
    },
    loadMoreVideos() {
      // console.log('xvf', 'Scrolled to the bottom, loading more videos...');
      this.nextPage(); // Assuming nextPage is the function to load more content
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube_search {
  // width: auto;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: center;
  // padding: 1rem;
  // max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  &__list {
    // width: 100%;
    max-width: 220px;
    // width: 100%;
    // height: 120px;
    // display: flex;
    justify-content: space-between;
    margin: 1rem;
    border-radius: 3px;
    background-color: #fff;
    padding: 0.25rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      // box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  &__detail {
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;

    .heading {
      width: auto;
    }
    .meta {
      display: flex;
      justify-content: space-between;
    }
  }

  &__thumbnail {
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;

    img {
      min-width: 100%;
      max-width: 360px;
      min-height: 100%;
      max-height: 120px;
      transition: all 0.3s ease-in-out;
      border-radius: 3px;

      // &:hover {
      //   cursor: pointer;
      //   border-radius: 3px;
      //   transform: scale(1.1);
      // }
    }
    .iframe-widget {
      width: 100%;
      height: 100%;
      pointer-events: none;
      // position: absolute;
      // transition: all 0.3s ease-in-out;
      // border-radius: 3px;

      // &:hover {
      //   cursor: pointer;
      // }
    }
  }
}
.video-player {
  max-height: 26rem;
  position: relative;
  margin-top: 1rem;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #76797b42;
  &__frame {
    width: 100%;
    max-height: 25rem;
    aspect-ratio: 2/1;
  }
  &__empty {
    max-height: 26rem;
    width: 100%;
  }
  &__icon {
    fill: red;
  }
  &__bottom {
    color: #76797b;
  }
  &__close {
    margin-left: auto;
    position: absolute;
    top: -2rem;
    right: 0.1rem;
  }
}
</style>
